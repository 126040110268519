import { Action } from '../Action';

const initialState = {
  path: '/products'
};

type State = typeof initialState;

export function routing(state: State = initialState, action: Action): State {
  if (action.type === 'routing/path') {
    return { ...state, path: action.value };
  }
  return state;
}

export function changePath(path: string): Action {
  return { type: 'routing/path', value: path };
}
