import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { SAVE_ACCOUNT, saveAccountFail, saveAccountSuccess } from '../reducers/accounts';
import { saveAccount, saveAccountPassword } from '../../api/api';

export function saveAccountEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(SAVE_ACCOUNT),
    mergeMap(action =>
      from(saveAccount(action.value.account)).pipe(
        mergeMap(account =>
          from(saveAccountPassword(account.id, action.value.account.password)).pipe(
            map(() => saveAccountSuccess(account)),
            catchError(error => of(saveAccountFail(error)))
          )
        ),
        catchError(error => of(saveAccountFail(error)))
      )
    )
  );
}
