import { Action } from '../Action';
import { Tenant } from '../../api/Tenant';

export const FETCH_TENANTS = 'FETCH_TENANTS';
export const FETCH_TENANTS_SUCCESS = 'FETCH_TENANTS_SUCCESS';
export const FETCH_TENANTS_FAIL = 'FETCH_TENANTS_FAIL';

export const SAVE_TENANT = 'SAVE_TENANT';
export const SAVE_TENANT_SUCCESS = 'SAVE_TENANT_SUCCESS';
export const SAVE_TENANT_FAIL = 'SAVE_TENANT_FAIL';

const initialState = {
  tenants: [] as Tenant[],
  loading: false
};

type State = typeof initialState;

export function tenants(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_TENANTS:
    case SAVE_TENANT:
      return { ...state, loading: true };
    case FETCH_TENANTS_SUCCESS:
      return { ...state, loading: false, tenants: action.value.tenants };
    case FETCH_TENANTS_FAIL:
      return { ...state, loading: false, tenants: [] };
    case SAVE_TENANT_SUCCESS:
      return { ...state, loading: false, tenants: [...state.tenants, action.value.tenant] };
    case SAVE_TENANT_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export function fetchTenants(): Action {
  return { type: FETCH_TENANTS };
}

export function fetchTenantsSuccess(tenants: Tenant[]): Action {
  return { type: FETCH_TENANTS_SUCCESS, value: { tenants } };
}

export function fetchTenantsFail(error: unknown): Action {
  return { type: FETCH_TENANTS_FAIL, value: { error } };
}

export function saveTenant(tenant: Tenant): Action {
  return { type: SAVE_TENANT, value: { tenant } };
}

export function saveTenantSuccess(tenant: Tenant): Action {
  return { type: SAVE_TENANT_SUCCESS, value: { tenant } };
}

export function saveTenantFail(error: unknown): Action {
  return { type: SAVE_TENANT_FAIL, value: { error } };
}
