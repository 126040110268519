import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { fetchScreenings, SAVE_PRODUCTS_SUGGESTION, saveProductsSuggestionFail } from '../reducers/screenings';
import { saveProductsSuggestion } from '../../api/api';

export function saveProductsSuggestionEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(SAVE_PRODUCTS_SUGGESTION),
    mergeMap(action =>
      from(
        saveProductsSuggestion(
          action.value.placeId,
          action.value.clientId,
          action.value.screeningId,
          action.value.productsSuggestion
        )
      ).pipe(
        map(() => fetchScreenings(action.value.placeId, action.value.clientId)),
        catchError(error => of(saveProductsSuggestionFail(error)))
      )
    )
  );
}
