import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { Action } from '../Action';
import { DELETE_SCREENING, deleteScreeningFail, deleteScreeningSuccess } from '../reducers/screenings';
import { deleteScreening } from '../../api/api';

export function deleteScreeningEpic(actions$: Observable<Action>): Observable<Action> {
  return actions$.pipe(
    ofType(DELETE_SCREENING),
    mergeMap(action =>
      from(deleteScreening(action.value.placeId, action.value.clientId, action.value.screeningId)).pipe(
        map(res => deleteScreeningSuccess(res)),
        catchError(error => of(deleteScreeningFail(error)))
      )
    )
  );
}
