import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { FETCH_ACCOUNT_ROLES, fetchAccountRolesFail, fetchAccountRolesSuccess } from '../reducers/accountRoles';
import { fetchTenantAccountRoles } from '../../api/api';

export function fetchAccountRolesEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_ACCOUNT_ROLES),
    mergeMap(action =>
      from(fetchTenantAccountRoles(action.value.placeId)).pipe(
        map(accountRoles => fetchAccountRolesSuccess(accountRoles)),
        catchError(error => of(fetchAccountRolesFail(error)))
      )
    )
  );
}
