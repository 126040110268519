import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { UPDATE_CLIENT, updateClientFail, updateClientSuccess } from '../reducers/clients';
import { updateClientCard } from '../../api/api';

export function updateClientEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(UPDATE_CLIENT),
    mergeMap(action =>
      from(updateClientCard(action.value.placeId, action.value.client.id, action.value.client)).pipe(
        map(client => updateClientSuccess(client)),
        catchError(error => of(updateClientFail(error)))
      )
    )
  );
}
