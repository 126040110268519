import axios, { AxiosRequestConfig } from 'axios';
import { Account } from './Account';
import { Tenant } from './Tenant';
import { AccountRole } from './AccountRole';
import { NutritionProduct } from './NutritionProduct';
import { ProductSuggestion } from './ProductSuggestion';
import { BulkMeasure } from './BulkMeasure';
import { Measure } from './Measure';
import { Notification } from './Notification';

export class AccessToken {
  accessToken: string;
}

// authentication endpoints

export async function authenticate(userName: string, password: string): Promise<AccessToken> {
  const response = await axios.create().post<AccessToken>('/api/beta/authentication', { userName, password });
  return response.data;
}

// me endpoints

const instance = axios.create();
instance.interceptors.request.use(function (config: AxiosRequestConfig): AxiosRequestConfig {
  const accessToken = sessionStorage.getItem('accessToken');
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${accessToken}` }
  };
});

export async function fetchMeasures(tenantId: string, clientId: string): Promise<Measure[]> {
  const { data } = await instance.get<Measure[]>(`/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/measures`);
  return data;
}

export async function saveBulkMeasure(tenantId: string, measures: BulkMeasure[]): Promise<void> {
  const response = await instance.post(`/api/beta/me/tenants/${tenantId}/bulk-measure`, measures);
}

export async function fetchMyAccount(): Promise<Account> {
  const response = await instance.get<Account>('/api/beta/me');
  return response.data;
}

export async function fetchMyRoles(): Promise<string[]> {
  const response = await instance.get<string[]>('/api/beta/me/roles');
  return response.data;
}

export async function changePassword(changePassword: any): Promise<any> {
  const response = await instance.post('/api/beta/me/change-password', changePassword);
  return response.data;
}

export async function fetchMyTenants(): Promise<any[]> {
  const response = await instance.get<any[]>('/api/beta/me/tenants');
  return response.data;
}

export interface Address {
  street: string;
  city: string;
  zipCode: string;
}

export interface Contact {
  email: string;
  phone: string;
}

export interface Client {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  gender: string;
  personaIdentificationNumber: string;
  birthdate: string;
  healthInsuranceCompanyCode: string;
  address: Address;
  contact: Contact;
  lastMnaResult?: number;
}

export async function fetchClientCards(tenantId: string): Promise<Client[]> {
  const response = await instance.get<Client[]>(`/api/beta/me/tenants/${tenantId}/client-cards`);
  return response.data;
}

export async function fetchClientCardsWithWarning(): Promise<any> {
  const response = await instance.get(`/api/beta/me/client-cards/warning`);
  return response.data;
}

export async function saveClientCard(tenantId: string, client: Client): Promise<Client> {
  const response = await instance.post<Client>(`/api/beta/me/tenants/${tenantId}/client-cards`, client);
  return response.data;
}

export async function fetchClientCard(tenantId: string, clientId: string): Promise<Client> {
  const response = await instance.get<Client>(`/api/beta/me/tenants/${tenantId}/client-cards/${clientId}`);
  return response.data;
}

export async function deleteClientCard(tenantId: string, clientId: string): Promise<void> {
  await instance.delete(`/api/beta/me/tenants/${tenantId}/client-cards/${clientId}`);
}

export interface Amputation {
  leftHand: string;
  rightHand: string;
  leftLeg: string;
  rightLeg: string;
}

export interface Diagnosis {
  first?: string;
  second?: string;
  amputation: Amputation;
  amputationSum: number;
}

export async function fetchDiagnosis(tenantId: string, clientId: any): Promise<Diagnosis> {
  const response = await instance.get<Diagnosis>(`/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/diagnosis`);
  return response.data;
}

export async function updateDiagnosis(tenantId: string, clientId: any, diagnosis: Diagnosis): Promise<Diagnosis> {
  const response = await instance.put<Diagnosis>(
    `/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/diagnosis`,
    diagnosis
  );
  return response.data;
}

export async function updateClientCard(tenantId: string, clientId: any, client: Client): Promise<Client> {
  const response = await instance.put<Client>(`/api/beta/me/tenants/${tenantId}/client-cards/${clientId}`, client);
  return response.data;
}

export async function fetchScreenings(tenantId: string, clientId: any): Promise<MNAScreening[]> {
  const response = await instance.get<MNAScreening[]>(
    `/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/mna-screenings`
  );
  return response.data;
}

export async function deleteScreening(
  tenantId: string,
  clientId: string,
  screeningId: string
): Promise<{ id: string; status: string }> {
  const response = await instance.delete<{ id: string; status: string }>(
    `/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/mna-screenings/${screeningId}`
  );
  return response.data;
}

export async function saveMessage(
  tenantId: string,
  clientId: string,
  screeningId: string,
  message: string,
  sendNotification: boolean
): Promise<void> {
  const response = await instance.post<void>(
    `/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/mna-screenings/${screeningId}/messages`,
    { message, sendNotification }
  );
  return response.data;
}

export async function saveProductsSuggestion(
  tenantId: string,
  clientId: string,
  screeningId: string,
  productsSuggestion: NutritionProduct[]
): Promise<void> {
  const response = await instance.post<void>(
    `/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/mna-screenings/${screeningId}/products-suggestion`,
    { productsSuggestion }
  );
  return response.data;
}

export async function fetchProducts(clientId?: string): Promise<NutritionProduct[]> {
  const response = await instance.get<NutritionProduct[]>(`/api/beta/me/products`, { params: { clientId } });
  return response.data;
}

export interface NutritionDiagnosis {
  id: string;
  name: string;
  note: string;
}

export async function fetchNutritionDiagnoses(): Promise<NutritionDiagnosis[]> {
  const response = await instance.get<NutritionDiagnosis[]>(`/api/beta/me/diagnoses`);
  return response.data;
}

export async function fetchNotifications(query: string): Promise<Notification[]> {
  const response = await instance.get<Notification[]>(`/api/beta/me/notifications`, { params: { query } });
  return response.data;
}

export async function setNotificationAsRead(notificationId: string): Promise<void> {
  await instance.put<void>(`/api/beta/me/notifications/${notificationId}/read`);
}

export async function fetchLoadedFiles(tenantId?: string, clientId?: string): Promise<any[]> {
  const response = await instance.get<any[]>(`/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/loadfiles`);
  return response.data;
}

export async function deleteLoadedFile(tenantId?: string, clientId?: string, fileId?: string): Promise<void> {
  await instance.delete<void>(`/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/loadfiles/${fileId}`);
}

export async function downloadLoadedFile(tenantId?: string, clientId?: string, fileId?: string): Promise<Blob> {
  const response = await instance.get<Blob>(
    `/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/loadfiles/${fileId}`,
    { responseType: 'blob' }
  );
  return response.data;
}

export class Message {
  userId: string;
  userName: string;
  fullName: string;
  message: string;
  date: string;
  role: 'ROLE_A' | 'ROLE_B' | 'ROLE_C';
}

export interface Data {
  screening: {
    DietVal: [string, number];
    WeightSet: number;
    WeightCalc: number;
    HeightSet: number;
    BMIcalc: number;
    BmiVal: string;
    FoodDecrease: string;
    WeightDecrease: string;
    Mobility: string;
    PsychInfl: string;
    NeuroPsychInfl: string;
    MnaRes: number;
    MnaResText: string;
  };
  anamnesis: {
    DietValSel: [string, number];
    BreakFast: string;
    Lunch: string;
    Dinner: string;
    CDPRE: number;
    foodSupplements: number;
    foodSupplementProducts: { product: NutritionProduct; count: number }[];
    FluidIntake: number;
    FluidIntakeWay: number;
    AlbVal: string;
    ActivityFactor: string;
    ActivityFactorHealthy: string;
    ActivityFactorIll: string;
    TemperatureFactor: string;
    PostTermFactor: string;
    SerIllFactor: string;
    IM: number;
    BVE: number;
    CDPE: number;
    ND: number;
  };
}

export class MNAScreening {
  id: string;
  createdBy?: {
    id: string;
    fullName: string;
  };
  created: string;
  data: Data;
  messageNurse?: Message;
  messageNutritionist?: Message;
  messageDoctor?: Message;
  productsSuggestion: ProductSuggestion[];
  albumin?: Measure;
}

export async function saveScreening(
  tenantId: string,
  clientId: any,
  screening: Omit<MNAScreening, 'id'>
): Promise<MNAScreening> {
  const response = await instance.post<MNAScreening>(
    `/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/mna-screenings`,
    screening
  );
  return response.data;
}

export async function saveWarning(tenantId: string, clientId: any): Promise<any> {
  const response = await instance.post(`/api/beta/me/tenants/${tenantId}/client-cards/${clientId}/warnings`, undefined);
  return response.data;
}

// admin endpoints

export async function fetchTenants(): Promise<Tenant[]> {
  const response = await instance.get<Tenant[]>('/api/beta/admin/tenants');
  return response.data;
}

export async function saveTenant(tenant: Tenant): Promise<Tenant> {
  const response = await instance.post<Tenant>('/api/beta/admin/tenants', tenant);
  return response.data;
}

export async function fetchTenantAccountRoles(tenantId: string): Promise<AccountRole[]> {
  const response = await instance.get<AccountRole[]>(`/api/beta/admin/tenants/${tenantId}/account-roles`);
  return response.data;
}

export async function saveTenantAccountRole(tenantId: string, accountRole: AccountRole): Promise<void> {
  await instance.post(`/api/beta/admin/tenants/${tenantId}/account-roles`, accountRole);
}

export async function deleteTenantAccountRole(tenantId: string, accountId: string): Promise<void> {
  await instance.delete(`/api/beta/admin/tenants/${tenantId}/account-roles/${accountId}`);
}

export async function fetchAccounts(): Promise<Account[]> {
  const response = await instance.get<Account[]>('/api/beta/admin/accounts');
  return response.data;
}

export async function fetchNutritionProducts(): Promise<NutritionProduct[]> {
  const response = await instance.get<NutritionProduct[]>('/api/beta/admin/nutrition-products');
  return response.data;
}

export async function fetchNutritionProductDiagnoses(productId: string): Promise<any> {
  const response = await instance.get(`/api/beta/admin/nutrition-products/${productId}/diagnoses`);
  return response.data;
}

export async function enableNutritionProductDiagnoses(productId: string, diagnosisId: string): Promise<any> {
  const response = await instance.post(`/api/beta/admin/nutrition-products/${productId}/diagnoses`, { diagnosisId });
  return response.data;
}

export async function disableNutritionProductDiagnoses(productId: string, diagnosisId: string): Promise<any> {
  const response = await instance.delete(`/api/beta/admin/nutrition-products/${productId}/diagnoses/${diagnosisId}`);
  return response.data;
}

export async function saveAccount(account: Account): Promise<Account> {
  const response = await instance.post<Account>('/api/beta/admin/accounts', account);
  return response.data;
}

export async function updateAccount(account: Account): Promise<void> {
  const response = await instance.put(`/api/beta/admin/accounts/${account.id}`, account);
}

export async function saveAccountPassword(accountId: string, password: any): Promise<void> {
  return instance.post(`/api/beta/admin/accounts/${accountId}/password`, { password });
}
