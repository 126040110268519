import { combineEpics } from 'redux-observable';
import { fetchProductEpic } from './fetchProductEpic';
import { fetchDiagnosesOfProduct } from './fetchDiagnosesOfProduct';
import { enableDiagnosesEpic } from './enableDiagnosesEpic';
import { disableDiagnosesEpic } from './disableDiagnosesEpic';
import { loginEpic } from './loginEpic';
import { fetchAccountEpic } from './fetchAccountEpic';
import { fetchRolesEpic } from './fetchRolesEpic';
import { fetchPlacesEpic } from './fetchPlacesEpic';
import { fetchClientsEpic } from './fetchClientsEpic';
import { fetchScreeningsEpic } from './fetchScreeningsEpic';
import { fetchAccountsEpic } from './fetchAccountsEpic';
import { saveAccountEpic } from './saveAccountEpic';
import { updateAccountEpic } from './updateAccountEpic';
import { fetchTenantsEpic } from './fetchTenantsEpic';
import { fetchAccountRolesEpic } from './fetchAccountRolesEpic';
import { addAccountRoleEpic } from './addAccountRoleEpic';
import { saveTenantEpic } from './saveTenantEpic';
import { removeAccountRoleEpic } from './removeAccountRole';
import { fetchDiagnosisEpic } from './fetchDiagnosisEpic';
import { fetchNutritionDiagnosesEpic } from './fetchNutritionDiagnosesEpic';
import { saveDiagnosisEpic } from './saveDiagnosisEpic';
import { updateClientEpic } from './updateClientEpic';
import { saveClientEpic } from './saveClientEpic';
import { saveScreeningEpic } from './saveScreeningEpic';
import { saveMessageEpic } from './saveMessageEpic';
import { fetchNutritionProductsEpic } from './fetchNutritionProductsEpic';
import { saveProductsSuggestionEpic } from './saveProductsSuggestionEpic';
import { showMessageEpic } from './showMessageEpic';
import { deleteScreeningEpic } from './deleteScreeningEpic';
import { deleteClientEpic } from './deleteClientEpic';
import { saveBulkMeasureEpic } from './saveBulkMeasureEpic';
import { fetchMeasuresEpic } from './fetchMeasuresEpic';

export default combineEpics(
  fetchProductEpic,
  fetchDiagnosesOfProduct,
  enableDiagnosesEpic,
  disableDiagnosesEpic,
  loginEpic,
  fetchAccountEpic,
  fetchPlacesEpic,
  fetchRolesEpic,
  fetchClientsEpic,
  fetchScreeningsEpic,
  fetchAccountsEpic,
  saveAccountEpic,
  updateAccountEpic,
  fetchTenantsEpic,
  fetchAccountRolesEpic,
  addAccountRoleEpic,
  saveTenantEpic,
  removeAccountRoleEpic,
  fetchDiagnosisEpic,
  fetchNutritionDiagnosesEpic,
  saveDiagnosisEpic,
  updateClientEpic,
  saveClientEpic,
  saveScreeningEpic,
  saveMessageEpic,
  fetchNutritionProductsEpic,
  saveProductsSuggestionEpic,
  showMessageEpic,
  deleteScreeningEpic,
  deleteClientEpic,
  saveBulkMeasureEpic,
  fetchMeasuresEpic
);
