import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { Action } from '../Action';
import { fetchMyTenants } from '../../api/api';
import { FETCH_PLACES, fetchPlacesFail, fetchPlacesSuccess } from '../reducers/places';

export function fetchPlacesEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_PLACES),
    mergeMap(() =>
      from(fetchMyTenants()).pipe(
        map(places => fetchPlacesSuccess(places)),
        catchError(() => of(fetchPlacesFail()))
      )
    )
  );
}
