import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { Provider } from 'react-redux';
import moment from 'moment';

import './index.less';

import reportWebVitals from './reportWebVitals';
import initializeStore from './store/initializeStore';
import { loginSuccessAction } from './store/reducers/login';
import { addQuickLoginUsers, enableQuickLogin } from './store/reducers/quickLogin';
import { QueryClient, QueryClientProvider } from 'react-query';

const NODE_ENV = process.env.NODE_ENV;
const REACT_APP_QUICK_LOGIN = process.env.REACT_APP_QUICK_LOGIN;
const REACT_APP_QUICK_LOGIN_USERS = process.env.REACT_APP_QUICK_LOGIN_USERS ?? '';

const Main = lazy(() => import('./Main'));

const store = initializeStore();

if (NODE_ENV === 'development') {
  if (REACT_APP_QUICK_LOGIN === 'enabled') {
    store.dispatch(enableQuickLogin());
    const users = REACT_APP_QUICK_LOGIN_USERS.split(';')
      .map(data => data.split(':'))
      .map(([userName, password]) => ({
        userName,
        password
      }));
    store.dispatch(addQuickLoginUsers(users));
  }
}

const token = sessionStorage.getItem('accessToken');
if (token !== null) {
  store.dispatch(loginSuccessAction(token));
}
moment.locale('cs_CZ');

const client = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <ConfigProvider locale={{ locale: 'cs_CZ' }} componentSize="middle">
        <Provider store={store}>
          <BrowserRouter>
            <Suspense fallback={<Spin size="large" />}>
              <Main />
            </Suspense>
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
