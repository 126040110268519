import { Diagnosis } from '../../api/api';
import { Action } from '../Action';

export const FETCH_DIAGNOSIS = 'FETCH_DIAGNOSIS';
export const FETCH_DIAGNOSIS_SUCCESS = 'FETCH_DIAGNOSIS_SUCCESS';
export const FETCH_DIAGNOSIS_FAIL = 'FETCH_DIAGNOSIS_FAIL';

export const SAVE_DIAGNOSIS = 'SAVE_DIAGNOSIS';
export const SAVE_DIAGNOSIS_SUCCESS = 'SAVE_DIAGNOSIS_SUCCESS';
export const SAVE_DIAGNOSIS_FAIL = 'SAVE_DIAGNOSIS_FAIL';

const initialState = {
  diagnosis: {
    amputation: { leftHand: '1', leftLeg: '1', rightHand: '1', rightLeg: '1' },
    amputationSum: 0
  } as Diagnosis,
  loading: false
};

type State = typeof initialState;

export function diagnosis(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_DIAGNOSIS:
    case SAVE_DIAGNOSIS:
      return { ...state, loading: true };
    case FETCH_DIAGNOSIS_SUCCESS:
    case SAVE_DIAGNOSIS_SUCCESS:
      return { ...state, loading: false, diagnosis: action.value.diagnosis };
    case FETCH_DIAGNOSIS_FAIL:
    case SAVE_DIAGNOSIS_FAIL:
      return { ...state, loading: false, diagnosis: initialState.diagnosis };
    default:
      return state;
  }
}

export function fetchDiagnosis(placeId: string, clientId: string): Action {
  return { type: FETCH_DIAGNOSIS, value: { placeId, clientId } };
}

export function fetchDiagnosisSuccess(diagnosis: Diagnosis): Action {
  return { type: FETCH_DIAGNOSIS_SUCCESS, value: { diagnosis } };
}

export function fetchDiagnosisFail(error: unknown): Action {
  return { type: FETCH_DIAGNOSIS_FAIL, value: { error } };
}

export function saveDiagnosis(placeId: string, clientId: string, diagnosis: Diagnosis): Action {
  return { type: SAVE_DIAGNOSIS, value: { placeId, clientId, diagnosis } };
}

export function saveDiagnosisSuccess(diagnosis: Diagnosis): Action {
  return { type: SAVE_DIAGNOSIS_SUCCESS, value: { diagnosis } };
}

export function saveDiagnosisFail(error: unknown): Action {
  return { type: SAVE_DIAGNOSIS_FAIL, value: { error } };
}
