import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { Action } from '../Action';
import { FETCH_ROLES, fetchRolesFail, fetchRolesSuccess } from '../reducers/roles';
import { fetchMyRoles } from '../../api/api';

export function fetchRolesEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_ROLES),
    mergeMap(() =>
      from(fetchMyRoles()).pipe(
        map(roles => fetchRolesSuccess(roles)),
        catchError(() => of(fetchRolesFail()))
      )
    )
  );
}
