import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { fetchScreenings, SAVE_MESSAGE, saveMessageFail } from '../reducers/screenings';
import { saveMessage } from '../../api/api';

export function saveMessageEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(SAVE_MESSAGE),
    mergeMap(action =>
      from(
        saveMessage(
          action.value.placeId,
          action.value.clientId,
          action.value.screeningId,
          action.value.message,
          action.value.sendNotification
        )
      ).pipe(
        map(() => fetchScreenings(action.value.placeId, action.value.clientId)),
        catchError(error => of(saveMessageFail(error)))
      )
    )
  );
}
