import { EMPTY, merge, mergeMap, Observable, tap } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { SAVE_CLIENT_FAIL, SAVE_CLIENT_SUCCESS, UPDATE_CLIENT_FAIL, UPDATE_CLIENT_SUCCESS } from '../reducers/clients';
import { message } from 'antd';

export function showMessageEpic(action$: Observable<Action>): Observable<Action> {
  return merge(
    action$.pipe(
      ofType(UPDATE_CLIENT_SUCCESS, SAVE_CLIENT_SUCCESS),
      tap(() => message.success('Karta klienta uložena.', 2)),
      mergeMap(() => EMPTY)
    ),
    action$.pipe(
      ofType(UPDATE_CLIENT_FAIL, SAVE_CLIENT_FAIL),
      tap(() => message.error('Chyba při ukládání karty klienta.', 2)),
      mergeMap(() => EMPTY)
    )
  );
}
