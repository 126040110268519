import { from, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { fetchAccountRoles, REMOVE_ACCOUNT_ROLE, removeAccountRoleSuccess } from '../reducers/accountRoles';
import { deleteTenantAccountRole } from '../../api/api';

export function removeAccountRoleEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(REMOVE_ACCOUNT_ROLE),
    mergeMap(action =>
      from(deleteTenantAccountRole(action.value.placeId, action.value.accountId)).pipe(
        mergeMap(() => of(removeAccountRoleSuccess(), fetchAccountRoles(action.value.placeId)))
      )
    )
  );
}
