import { Action } from '../Action';
import { Account } from '../../api/Account';

export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAIL = 'FETCH_ACCOUNTS_FAIL';

export const SAVE_ACCOUNT = 'SAVE_ACCOUNT';
export const SAVE_ACCOUNT_SUCCESS = 'SAVE_ACCOUNT_SUCCESS';
export const SAVE_ACCOUNT_FAIL = 'SAVE_ACCOUNT_FAIL';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAIL = 'UPDATE_ACCOUNT_FAIL';

const initialState = {
  loading: false,
  accounts: [] as Account[]
};

type State = typeof initialState;

export function accounts(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_ACCOUNTS:
    case SAVE_ACCOUNT:
    case UPDATE_ACCOUNT:
      return { ...state, loading: true };
    case FETCH_ACCOUNTS_SUCCESS:
      return { ...state, loading: false, accounts: action.value.accounts };
    case FETCH_ACCOUNTS_FAIL:
      return { ...state, loading: false, accounts: [] };
    case SAVE_ACCOUNT_SUCCESS:
      return { ...state, loading: false, accounts: [...state.accounts, action.value.account] };
    case SAVE_ACCOUNT_FAIL:
      return { ...state, loading: false };
    case UPDATE_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export function fetchAccounts(): Action {
  return { type: FETCH_ACCOUNTS };
}

export function fetchAccountsSuccess(accounts: Account[]): Action {
  return { type: FETCH_ACCOUNTS_SUCCESS, value: { accounts } };
}

export function fetchAccountsFail(error: unknown): Action {
  return { type: FETCH_ACCOUNTS_FAIL, value: { error } };
}

export function saveAccount(account: Account & { password: string }): Action {
  return { type: SAVE_ACCOUNT, value: { account } };
}

export function saveAccountSuccess(account: Account): Action {
  return { type: SAVE_ACCOUNT_SUCCESS, value: { account } };
}

export function saveAccountFail(error: unknown): Action {
  return { type: SAVE_ACCOUNT_FAIL, value: { error } };
}

export function updateAccount(account: Account): Action {
  return { type: UPDATE_ACCOUNT, value: { account } };
}

export function updateAccountSuccess(): Action {
  return { type: UPDATE_ACCOUNT_SUCCESS };
}

export function updateAccountFail(error: unknown): Action {
  return { type: UPDATE_ACCOUNT_FAIL, value: { error } };
}
