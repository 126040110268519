import { Action } from '../Action';
import type { SorterResult } from 'antd/es/table/interface';
import { Client } from '../../api/api';
import { TablePaginationConfig } from 'antd';

export const UPDATE_CLIENT_LIST_SORTING = 'UPDATE_CLIENT_LIST_SORTING';

const initialState = {
  clientListTableConfig: {
    clientListSort: {} as SorterResult<Client>,
    paginationConfig: {} as TablePaginationConfig
  }
};

type State = typeof initialState;

export function pagesSetting(state: State = initialState, action: Action): State {
  switch (action.type) {
    case UPDATE_CLIENT_LIST_SORTING:
      return { ...state, clientListTableConfig: action.value };
    default:
      return state;
  }
}

export function updateClientListSorting(clientListTableConfig: {
  clientListSort: SorterResult<Client>;
  paginationConfig: TablePaginationConfig;
}): Action {
  return { type: UPDATE_CLIENT_LIST_SORTING, value: clientListTableConfig };
}
