import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { saveBulkMeasure } from '../../api/api';
import { SAVE_BULK_MEASURE, saveBulkMeasureFail, saveBulkMeasureSuccess } from '../reducers/bulkMeasure';

export function saveBulkMeasureEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(SAVE_BULK_MEASURE),
    mergeMap(action =>
      from(saveBulkMeasure(action.value.tenantId, action.value.measures)).pipe(
        map(() => saveBulkMeasureSuccess()),
        catchError(error => of(saveBulkMeasureFail(error)))
      )
    )
  );
}
