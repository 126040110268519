import { Action } from '../Action';
import { Measure } from '../../api/Measure';

export const FETCH_MEASURES = 'FETCH_MEASURES';
export const FETCH_MEASURES_SUCCESS = 'FETCH_MEASURES_SUCCESS';
export const FETCH_MEASURES_FAIL = 'FETCH_MEASURES_FAIL';

const initialState = {
  measures: [] as Measure[],
  loading: false as boolean,
  error: undefined as any
};

type State = typeof initialState;

export function measures(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_MEASURES:
      return { ...state, loading: true };
    case FETCH_MEASURES_SUCCESS:
      return { ...state, loading: false, measures: action.value.measures };
    case FETCH_MEASURES_FAIL:
      return { ...state, loading: false, measures: [], error: action.value.error };
    default:
      return state;
  }
}

export function fetchMeasure(tenantId: string, clientId: string): Action {
  return { type: FETCH_MEASURES, value: { tenantId, clientId } };
}

export function fetchMeasureSuccess(measures: Measure[]): Action {
  return { type: FETCH_MEASURES_SUCCESS, value: { measures } };
}

export function fetchMeasureFail(error: unknown): Action {
  return { type: FETCH_MEASURES_FAIL, value: { error } };
}
