import { Action } from '../Action';
import { BulkMeasure } from '../../api/BulkMeasure';

export const SAVE_BULK_MEASURE = 'SAVE_BULK_MEASURE';
export const SAVE_BULK_MEASURE_SUCCESS = 'SAVE_BULK_MEASURE_SUCCESS';
export const SAVE_BULK_MEASURE_FAIL = 'SAVE_BULK_MEASURE_FAIL';

const initialState = {
  loading: false as boolean,
  error: undefined as any
};

type State = typeof initialState;

export function bulkMeasure(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SAVE_BULK_MEASURE:
      return { ...state, loading: true };
    case SAVE_BULK_MEASURE_SUCCESS:
      return { ...state, loading: false };
    case SAVE_BULK_MEASURE_FAIL:
      return { ...state, loading: false, error: action.value.error };
    default:
      return state;
  }
}

export function saveBulkMeasure(tenantId: string, measures: BulkMeasure[]): Action {
  return { type: SAVE_BULK_MEASURE, value: { tenantId, measures } };
}

export function saveBulkMeasureSuccess(): Action {
  return { type: SAVE_BULK_MEASURE_SUCCESS };
}

export function saveBulkMeasureFail(error: any): Action {
  return { type: SAVE_BULK_MEASURE_FAIL, value: { error } };
}
