import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { disableNutritionProductDiagnoses } from '../../api/api';
import { fetchDiagnoses, fetchProductsError } from '../reducers/products';

export function disableDiagnosesEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType('diagnoses/disable'),
    mergeMap(action =>
      from(disableNutritionProductDiagnoses(action.value.productId, action.value.diagnosisId)).pipe(
        map(() => fetchDiagnoses(action.value.productId)),
        catchError(() => of(fetchProductsError()))
      )
    )
  );
}
