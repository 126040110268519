import { ofType } from 'redux-observable';
import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';

import { Action } from '../Action';
import { FETCH_ACCOUNTS, fetchAccountsFail, fetchAccountsSuccess } from '../reducers/accounts';
import { fetchAccounts } from '../../api/api';

export function fetchAccountsEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_ACCOUNTS),
    mergeMap(() =>
      from(fetchAccounts()).pipe(
        map(accounts => fetchAccountsSuccess(accounts)),
        catchError(error => of(fetchAccountsFail(error)))
      )
    )
  );
}
