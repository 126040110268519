import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { UPDATE_ACCOUNT, updateAccountFail, updateAccountSuccess } from '../reducers/accounts';
import { updateAccount } from '../../api/api';

export function updateAccountEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(UPDATE_ACCOUNT),
    mergeMap(action =>
      from(updateAccount(action.value.account)).pipe(
        map(() => updateAccountSuccess()),
        catchError(error => of(updateAccountFail(error)))
      )
    )
  );
}
