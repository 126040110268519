import { NutritionProduct } from '../../api/NutritionProduct';
import { Action } from '../Action';

export const FETCH_NUTRITION_PRODUCTS = 'FETCH_NUTRITION_PRODUCTS';
export const FETCH_NUTRITION_PRODUCTS_SUCCESS = 'FETCH_NUTRITION_PRODUCTS_SUCCESS';
export const FETCH_NUTRITION_PRODUCTS_FAIL = 'FETCH_NUTRITION_PRODUCTS_FAIL';

const initialState = {
  products: [] as NutritionProduct[],
  loading: false
};

type State = typeof initialState;

export function nutritionProducts(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_NUTRITION_PRODUCTS:
      return { ...state, loading: true };
    case FETCH_NUTRITION_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.value.products };
    case FETCH_NUTRITION_PRODUCTS_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export function fetchNutritionProducts(clientId: string): Action {
  return { type: FETCH_NUTRITION_PRODUCTS, value: { clientId } };
}

export function fetchNutritionProductsSuccess(products: NutritionProduct[]): Action {
  return { type: FETCH_NUTRITION_PRODUCTS_SUCCESS, value: { products } };
}

export function fetchNutritionProductsFail(error: unknown): Action {
  return { type: FETCH_NUTRITION_PRODUCTS_FAIL, value: { error } };
}
