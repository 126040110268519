import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { Action } from '../Action';
import { LOGIN, loginFailedAction, loginSuccessAction } from '../reducers/login';
import { authenticate } from '../../api/api';

export function loginEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(LOGIN),
    mergeMap(({ value: { userName, password } }: any) =>
      from(authenticate(userName, password)).pipe(
        map(result => loginSuccessAction(result.accessToken)),
        catchError(() => of(loginFailedAction()))
      )
    )
  );
}
