import { Action } from '../Action';
import { Account } from '../../api/Account';
import { LOGOUT } from './login';

export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_FAIL = 'FETCH_ACCOUNT_FAIL';

const initialState = {
  account: undefined as Account | undefined,
  loading: true
};

type State = typeof initialState;

export function account(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_ACCOUNT:
      return { ...state, loading: true };
    case FETCH_ACCOUNT_SUCCESS:
      return { ...state, loading: false, account: action.value.account };
    case FETCH_ACCOUNT_FAIL:
      return { ...state, loading: false, account: undefined };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}

export function fetchAccount(): Action {
  return { type: FETCH_ACCOUNT };
}

export function fetchAccountSuccess(account: Account): Action {
  return { type: FETCH_ACCOUNT_SUCCESS, value: { account } };
}

export function fetchAccountFail(): Action {
  return { type: FETCH_ACCOUNT_FAIL };
}
