import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import {
  FETCH_NUTRITION_PRODUCTS,
  fetchNutritionProductsFail,
  fetchNutritionProductsSuccess
} from '../reducers/nutritionProducts';
import { fetchProducts } from '../../api/api';

export function fetchNutritionProductsEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_NUTRITION_PRODUCTS),
    mergeMap((action: Action) =>
      from(fetchProducts(action.value.clientId)).pipe(
        map(products => fetchNutritionProductsSuccess(products)),
        catchError(error => of(fetchNutritionProductsFail(error)))
      )
    )
  );
}
