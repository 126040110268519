import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { fetchMyAccount } from '../../api/api';
import { FETCH_ACCOUNT, fetchAccountFail, fetchAccountSuccess } from '../reducers/account';
import { Action } from '../Action';

export function fetchAccountEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_ACCOUNT),
    mergeMap(() =>
      from(fetchMyAccount()).pipe(
        map(account => fetchAccountSuccess(account)),
        catchError(() => of(fetchAccountFail()))
      )
    )
  );
}
