import { ofType } from 'redux-observable';
import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';

import { fetchTenants } from '../../api/api';
import { FETCH_TENANTS, fetchTenantsFail, fetchTenantsSuccess } from '../reducers/tenants';
import { Action } from '../Action';

export function fetchTenantsEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_TENANTS),
    mergeMap(() =>
      from(fetchTenants()).pipe(
        map(tenants => fetchTenantsSuccess(tenants)),
        catchError(error => of(fetchTenantsFail(error)))
      )
    )
  );
}
