import { Action } from '../Action';

export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login/success';
export const LOGIN_FAILED = 'login/failed';
export const LOGOUT = 'logout';

export function loginAction(userName: string, password: string): Action {
  return { type: LOGIN, value: { userName, password } };
}

export function loginSuccessAction(accessToken: string): Action {
  return { type: LOGIN_SUCCESS, value: { accessToken } };
}

export function loginFailedAction(): Action {
  return { type: LOGIN_FAILED };
}

export function logoutAction(): Action {
  return { type: LOGOUT };
}

const initialState = {
  loginFailed: false,
  loginInProgress: false,
  accessToken: undefined
};

type State = typeof initialState;

export function login(state: State = initialState, action: Action): State {
  switch (action.type) {
    case LOGIN:
      return { ...state, loginInProgress: true };
    case LOGIN_SUCCESS:
      sessionStorage.setItem('accessToken', action.value.accessToken);
      return { ...state, loginInProgress: false, loginFailed: false, accessToken: action.value.accessToken };
    case LOGIN_FAILED:
      sessionStorage.removeItem('accessToken');
      return { ...state, loginInProgress: false, loginFailed: true, accessToken: undefined };
    case LOGOUT:
      sessionStorage.removeItem('accessToken');
      return { ...state, loginInProgress: false, loginFailed: false, accessToken: undefined };
    default:
      return state;
  }
}
