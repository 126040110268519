import { NutritionDiagnosis } from '../../api/api';
import { Action } from '../Action';

export const FETCH_NUTRITION_DIAGNOSES = 'FETCH_NUTRITION_DIAGNOSES';
export const FETCH_NUTRITION_DIAGNOSES_SUCCESS = 'FETCH_NUTRITION_DIAGNOSES_SUCCESS';
export const FETCH_NUTRITION_DIAGNOSES_FAIL = 'FETCH_NUTRITION_DIAGNOSES_FAIL';

const initialState = {
  diagnoses: [] as NutritionDiagnosis[],
  loading: false
};

type State = typeof initialState;

export function nutritionDiagnoses(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_NUTRITION_DIAGNOSES:
      return { ...state, loading: false };
    case FETCH_NUTRITION_DIAGNOSES_SUCCESS:
      return { ...state, loading: false, diagnoses: action.value.diagnoses };
    case FETCH_NUTRITION_DIAGNOSES_FAIL:
      return { ...state, loading: false, diagnoses: [] };
    default:
      return state;
  }
}

export function fetchNutritionDiagnoses(): Action {
  return { type: FETCH_NUTRITION_DIAGNOSES };
}

export function fetchNutritionDiagnosesSuccess(diagnoses: NutritionDiagnosis[]): Action {
  return { type: FETCH_NUTRITION_DIAGNOSES_SUCCESS, value: { diagnoses } };
}

export function fetchNutritionDiagnosesFail(error: unknown): Action {
  return { type: FETCH_NUTRITION_DIAGNOSES_FAIL, value: { error } };
}
