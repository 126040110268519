import { Action } from '../Action';

export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';

const initialState = {
  loading: false,
  roles: [] as string[]
};

type State = typeof initialState;

export function roles(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_ROLES:
      return { ...state, loading: true };
    case FETCH_ROLES_SUCCESS:
      return { ...state, loading: false, roles: action.value.roles };
    case FETCH_ROLES_FAIL:
      return { ...state, loading: false, roles: [] };
    default:
      return state;
  }
}

export function fetchRoles(): Action {
  return { type: FETCH_ROLES };
}

export function fetchRolesSuccess(roles: string[]): Action {
  return { type: FETCH_ROLES_SUCCESS, value: { roles } };
}

export function fetchRolesFail(): Action {
  return { type: FETCH_ROLES_FAIL };
}
