import { Action } from '../Action';

export const ENABLE_QUICK_LOGIN = 'ENABLE_QUICK_LOGIN';
export const ADD_QUICK_LOGIN_USERS = 'ADD_QUICK_LOGIN_USERS';

const initialState = {
  users: [] as { userName: string; password: string }[],
  enabled: false
};

type State = typeof initialState;

export function quickLogin(state: State = initialState, action: Action): State {
  switch (action.type) {
    case ENABLE_QUICK_LOGIN:
      return { ...state, enabled: true };
    case ADD_QUICK_LOGIN_USERS:
      return { ...state, users: action.value.users };
    default:
      return state;
  }
}

export function enableQuickLogin(): Action {
  return { type: ENABLE_QUICK_LOGIN };
}

export function addQuickLoginUsers(users: { userName: string; password: string }[]): Action {
  return { type: ADD_QUICK_LOGIN_USERS, value: { users } };
}
