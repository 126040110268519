import { Action } from '../Action';
import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { SAVE_TENANT, saveTenantFail, saveTenantSuccess } from '../reducers/tenants';
import { saveTenant } from '../../api/api';

export function saveTenantEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(SAVE_TENANT),
    mergeMap(action =>
      from(saveTenant(action.value.tenant)).pipe(
        map(tenant => saveTenantSuccess(tenant)),
        catchError(error => of(saveTenantFail(error)))
      )
    )
  );
}
