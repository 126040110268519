import { Action } from '../Action';
import { AccountRole } from '../../api/AccountRole';

export const FETCH_ACCOUNT_ROLES = 'FETCH_ACCOUNT_ROLES';
export const FETCH_ACCOUNT_ROLES_SUCCESS = 'FETCH_ACCOUNT_ROLES_SUCCESS';
export const FETCH_ACCOUNT_ROLES_FAIL = 'FETCH_ACCOUNT_ROLES_FAIL';

export const ADD_ACCOUNT_ROLE = 'ADD_ACCOUNT_ROLE';
export const ADD_ACCOUNT_ROLE_SUCCESS = 'ADD_ACCOUNT_ROLE_SUCCESS';
export const ADD_ACCOUNT_ROLE_FAIL = 'ADD_ACCOUNT_ROLE_FAIL';

export const REMOVE_ACCOUNT_ROLE = 'REMOVE_ACCOUNT_ROLE';
export const REMOVE_ACCOUNT_ROLE_SUCCESS = 'REMOVE_ACCOUNT_ROLE_SUCCESS';
export const REMOVE_ACCOUNT_ROLE_FAIL = 'REMOVE_ACCOUNT_ROLE_FAIL';

const initialState = {
  accountRoles: [] as any[],
  loading: false
};
type State = typeof initialState;

export function accountRoles(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_ACCOUNT_ROLES:
    case ADD_ACCOUNT_ROLE:
    case REMOVE_ACCOUNT_ROLE:
      return { ...state, loading: true };
    case FETCH_ACCOUNT_ROLES_SUCCESS:
      return { ...state, loading: false, accountRoles: action.value.accountRoles };
    case FETCH_ACCOUNT_ROLES_FAIL:
      return { ...state, loading: false, accountRoles: [] };
    default:
      return state;
  }
}

export function fetchAccountRoles(placeId: string): Action {
  return { type: FETCH_ACCOUNT_ROLES, value: { placeId } };
}

export function fetchAccountRolesSuccess(accountRoles: any[]): Action {
  return { type: FETCH_ACCOUNT_ROLES_SUCCESS, value: { accountRoles } };
}

export function fetchAccountRolesFail(error: unknown): Action {
  return { type: FETCH_ACCOUNT_ROLES_FAIL, value: { error } };
}

export function addAccountRole(placeId: string, accountRole: AccountRole): Action {
  return { type: ADD_ACCOUNT_ROLE, value: { placeId, accountRole } };
}

export function addAccountRoleSuccess(): Action {
  return { type: ADD_ACCOUNT_ROLE_SUCCESS };
}

export function addAccountRoleFail(error: unknown): Action {
  return { type: ADD_ACCOUNT_ROLE_FAIL, value: { error } };
}

export function removeAccountRole(placeId: string, accountId: string): Action {
  return { type: REMOVE_ACCOUNT_ROLE, value: { placeId, accountId } };
}

export function removeAccountRoleSuccess(): Action {
  return { type: REMOVE_ACCOUNT_ROLE_SUCCESS };
}

export function removeAccountRoleFail(error: unknown): Action {
  return { type: REMOVE_ACCOUNT_ROLE_FAIL, value: { error } };
}
