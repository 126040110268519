import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { Action } from '../Action';
import { FETCH_MEASURES, fetchMeasureFail, fetchMeasureSuccess } from '../reducers/measures';
import { fetchMeasures } from '../../api/api';

export function fetchMeasuresEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_MEASURES),
    mergeMap(action =>
      from(fetchMeasures(action.value.tenantId, action.value.clientId)).pipe(
        map(measures => fetchMeasureSuccess(measures)),
        catchError(error => of(fetchMeasureFail(error)))
      )
    )
  );
}
