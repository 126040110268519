import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import {
  FETCH_NUTRITION_DIAGNOSES,
  fetchNutritionDiagnosesFail,
  fetchNutritionDiagnosesSuccess
} from '../reducers/nutritionDiagnoses';
import { fetchNutritionDiagnoses } from '../../api/api';

export function fetchNutritionDiagnosesEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_NUTRITION_DIAGNOSES),
    mergeMap(() =>
      from(fetchNutritionDiagnoses()).pipe(
        map(diagnoses => fetchNutritionDiagnosesSuccess(diagnoses)),
        catchError(error => of(fetchNutritionDiagnosesFail(error)))
      )
    )
  );
}
