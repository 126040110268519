import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { DELETE_CLIENT, deleteClientFail, deleteClientSuccess } from '../reducers/clients';
import { deleteClientCard } from '../../api/api';

export function deleteClientEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(DELETE_CLIENT),
    mergeMap(action =>
      from(deleteClientCard(action.value.placeId, action.value.clientId)).pipe(
        map(() => deleteClientSuccess(action.value.clientId)),
        catchError(error => of(deleteClientFail(error)))
      )
    )
  );
}
