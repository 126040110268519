import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { SAVE_DIAGNOSIS, saveDiagnosisFail, saveDiagnosisSuccess } from '../reducers/diagnosis';
import { ofType } from 'redux-observable';
import { updateDiagnosis } from '../../api/api';

export function saveDiagnosisEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(SAVE_DIAGNOSIS),
    mergeMap(action =>
      from(updateDiagnosis(action.value.placeId, action.value.clientId, action.value.diagnosis)).pipe(
        map(diagnosis => saveDiagnosisSuccess(diagnosis)),
        catchError(error => of(saveDiagnosisFail(error)))
      )
    )
  );
}
