import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { FETCH_DIAGNOSIS, fetchDiagnosisFail, fetchDiagnosisSuccess } from '../reducers/diagnosis';
import { Diagnosis, fetchDiagnosis } from '../../api/api';
import { amputationSum } from '../../functions/measures/ampcalc';

function prepare(diagnosis: Diagnosis): Diagnosis {
  if (diagnosis.first === null) diagnosis.first = undefined;
  if (diagnosis.second === null) diagnosis.second = undefined;
  if (diagnosis.amputation.leftHand === null) diagnosis.amputation.leftHand = '1';
  if (diagnosis.amputation.rightHand === null) diagnosis.amputation.rightHand = '1';
  if (diagnosis.amputation.leftLeg === null) diagnosis.amputation.leftLeg = '1';
  if (diagnosis.amputation.rightLeg === null) diagnosis.amputation.rightLeg = '1';

  const { leftHand, rightHand, leftLeg, rightLeg } = diagnosis.amputation;
  diagnosis.amputationSum = amputationSum(Number(leftHand), Number(rightHand), Number(leftLeg), Number(rightLeg));
  return diagnosis;
}

export function fetchDiagnosisEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_DIAGNOSIS),
    mergeMap(action =>
      from(fetchDiagnosis(action.value.placeId, action.value.clientId)).pipe(
        map(diagnosis => fetchDiagnosisSuccess(prepare(diagnosis))),
        catchError(error => of(fetchDiagnosisFail(error)))
      )
    )
  );
}
