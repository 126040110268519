import { combineReducers } from 'redux';
import { products } from './products';
import { routing } from './routing';
import { login } from './login';
import { account } from './account';
import { roles } from './roles';
import { places } from './places';
import { clients } from './clients';
import { screenings } from './screenings';
import { accounts } from './accounts';
import { tenants } from './tenants';
import { accountRoles } from './accountRoles';
import { diagnosis } from './diagnosis';
import { nutritionDiagnoses } from './nutritionDiagnoses';
import { nutritionProducts } from './nutritionProducts';
import { measures } from './measures';
import { quickLogin } from './quickLogin';
import { bulkMeasure } from './bulkMeasure';
import { pagesSetting } from './pagesSetting';

export const rootReducer = combineReducers({
  products,
  routing,
  login,
  account,
  roles,
  places,
  clients,
  screenings,
  accounts,
  tenants,
  accountRoles,
  diagnosis,
  nutritionDiagnoses,
  nutritionProducts,
  measures,
  quickLogin,
  bulkMeasure,
  pagesSetting
});

export type State = ReturnType<typeof rootReducer>;
