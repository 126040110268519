import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { Action } from '../Action';
import { fetchScreenings } from '../../api/api';
import { FETCH_SCREENINGS, fetchScreeningsFail, fetchScreeningsSuccess } from '../reducers/screenings';

export function fetchScreeningsEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_SCREENINGS),
    mergeMap(action =>
      from(fetchScreenings(action.value.placeId, action.value.clientId)).pipe(
        map(screenings => fetchScreeningsSuccess(screenings)),
        catchError(() => of(fetchScreeningsFail()))
      )
    )
  );
}
