import { Action } from '../Action';
import { Product } from '../../api/Product';
import { NutritionProduct } from '../../api/NutritionProduct';

const initialState = {
  loading: false,
  filter: '',
  select: undefined as string | undefined,
  data: [] as Product[],
  diagnoses: {} as {
    [productId: string]: any;
  }
};

type State = typeof initialState;

export function products(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'products/fetch':
      return { ...state, loading: true };
    case 'products/fetch/success':
      return { ...state, loading: false, data: action.value };
    case 'products/fetch/error':
      return { ...state, loading: false, data: [], diagnoses: {} };

    case 'diagnoses/fetch':
      return { ...state, loading: true };
    case 'diagnoses/fetch/success':
      return { ...state, loading: false, diagnoses: { ...state.diagnoses, ...action.value } };
    case 'diagnoses/fetch/error':
      return { ...state, loading: false, diagnoses: { ...state.diagnoses, ...action.value } };

    case 'products/filter':
      return { ...state, filter: action.value };
    default:
      return { ...state };
  }
}

export function filterProducts(filter: string): Action {
  return { type: 'products/filter', value: filter };
}

export function fetchProducts(): Action {
  return { type: 'products/fetch' };
}

export function fetchProductsSuccess(products: NutritionProduct[]): Action {
  return { type: 'products/fetch/success', value: products };
}

export function fetchProductsError(): Action {
  return { type: 'products/fetch/error' };
}

export function enableDiagnoses(productId: string, diagnosisId: string): Action {
  return { type: 'diagnoses/enable', value: { productId, diagnosisId } };
}

export function disableDiagnoses(productId: string, diagnosisId: string): Action {
  return { type: 'diagnoses/disable', value: { productId, diagnosisId } };
}

export function fetchDiagnoses(productId: string): Action {
  return { type: 'diagnoses/fetch', value: productId };
}

export function fetchDiagnosesSuccess(productId: string, diagnoses: any[]): Action {
  return { type: 'diagnoses/fetch/success', value: { [productId]: diagnoses } };
}

export function fetchDiagnosesError(productId: string): Action {
  return { type: 'diagnoses/fetch/error', value: { [productId]: undefined } };
}
