import { Action } from '../Action';

export const FETCH_PLACES = 'FETCH_PLACES';
export const FETCH_PLACES_SUCCESS = 'FETCH_PLACES_SUCCESS';
export const FETCH_PLACES_FAIL = 'FETCH_PLACES_FAIL';
export const SELECT_PLACE = 'SELECT_PLACE';

const initialState = {
  loading: false,
  places: [] as { id: string; name: string; role?: string }[],
  selected: 'UNKNOWN'
};

type State = typeof initialState;

export function places(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_PLACES:
      return { ...state, loading: true, selected: 'UNKNOWN' };
    case FETCH_PLACES_SUCCESS:
      return { ...state, loading: false, places: action.value.places, selected: action.value.places[0]?.id };
    case FETCH_PLACES_FAIL:
      return { ...state, loading: false, places: [], selected: 'UNKNOWN' };
    case SELECT_PLACE:
      return { ...state, selected: action.value.selected };
    default:
      return state;
  }
}

export function fetchPlaces(): Action {
  return { type: FETCH_PLACES };
}

export function fetchPlacesSuccess(places: any[]): Action {
  return { type: FETCH_PLACES_SUCCESS, value: { places } };
}

export function fetchPlacesFail(): Action {
  return { type: FETCH_PLACES_FAIL };
}

export function selectPlace(selected: string): Action {
  return { type: SELECT_PLACE, value: { selected } };
}
