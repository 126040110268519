import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';

import { Action } from '../Action';
import { fetchClientCards } from '../../api/api';
import { FETCH_CLIENTS, fetchClientsFail, fetchClientsSuccess } from '../reducers/clients';

export function fetchClientsEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(FETCH_CLIENTS),
    mergeMap(action =>
      from(fetchClientCards(action.value.placeId)).pipe(
        map(clients => fetchClientsSuccess(clients)),
        catchError(error => of(fetchClientsFail(error)))
      )
    )
  );
}
