import { catchError, from, map, mergeMap, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { fetchNutritionProductDiagnoses } from '../../api/api';

import { fetchDiagnosesError, fetchDiagnosesSuccess } from '../reducers/products';
import { Action } from '../Action';

export function fetchDiagnosesOfProduct(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType('diagnoses/fetch'),
    mergeMap((action: Action) =>
      from(fetchNutritionProductDiagnoses(action.value)).pipe(
        map(result => fetchDiagnosesSuccess(action.value, result)),
        catchError(() => of(fetchDiagnosesError(action.value())))
      )
    )
  );
}
