import amputation from '../../data/amputation.json';

export function legAmputationPercent(index: number): number {
  const amp = amputation.leg.find(amp => amp.code === index.toString());
  return amp?.value ?? 0;
}

export function handAmputationPercent(index: number): number {
  const amp = amputation.hand.find(amp => amp.code === index.toString());
  return amp?.value ?? 0;
}

export function amputationSum(
  leftHandCode: number,
  rightHandCode: number,
  leftLegCode: number,
  rightLegCode: number
): number {
  return ampcalc(
    handAmputationPercent(leftHandCode),
    handAmputationPercent(rightHandCode),
    legAmputationPercent(leftLegCode),
    legAmputationPercent(rightLegCode)
  );
}

export function ampcalc(LeftHand: number, RightHand: number, LeftLeg: number, RightLeg: number): number {
  return (LeftHand * 10 + RightHand * 10 + LeftLeg * 10 + RightLeg * 10) / 10;
}
