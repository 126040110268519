import { MNAScreening } from '../../api/api';
import { Action } from '../Action';
import { ProductSuggestion } from '../../api/ProductSuggestion';

export const FETCH_SCREENINGS = 'FETCH_SCREENINGS';
export const FETCH_SCREENINGS_SUCCESS = 'FETCH_SCREENINGS_SUCCESS';
export const FETCH_SCREENINGS_FAIL = 'FETCH_SCREENINGS_FAIL';

export const SAVE_SCREENING = 'SAVE_SCREENING';
export const SAVE_SCREENING_SUCCESS = 'SAVE_SCREENING_SUCCESS';
export const SAVE_SCREENING_FAIL = 'SAVE_SCREENING_FAIL';

export const DELETE_SCREENING = 'DELETE_SCREENING';
export const DELETE_SCREENING_SUCCESS = 'DELETE_SCREENING_SUCCESS';
export const DELETE_SCREENING_FAIL = 'DELETE_SCREENING_FAIL';

export const SAVE_MESSAGE = 'SAVE_MESSAGE';
export const SAVE_MESSAGE_FAIL = 'SAVE_MESSAGE_FAIL';

export const SAVE_PRODUCTS_SUGGESTION = 'SAVE_PRODUCTS_SUGGESTION';
export const SAVE_PRODUCTS_SUGGESTION_FAIL = 'SAVE_PRODUCTS_SUGGESTION_FAIL';

const initialState = {
  loading: false,
  screenings: [] as MNAScreening[]
};

type State = typeof initialState;

export function screenings(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_SCREENINGS:
    case SAVE_SCREENING:
    case SAVE_MESSAGE:
    case SAVE_PRODUCTS_SUGGESTION:
    case DELETE_SCREENING:
      return { ...state, loading: true };
    case FETCH_SCREENINGS_FAIL:
    case SAVE_SCREENING_FAIL:
    case SAVE_MESSAGE_FAIL:
    case SAVE_PRODUCTS_SUGGESTION_FAIL:
    case DELETE_SCREENING_FAIL:
      return { ...state, loading: false };
    case FETCH_SCREENINGS_SUCCESS:
      return { ...state, loading: false, screenings: action.value.screenings };
    case SAVE_SCREENING_SUCCESS:
      return { ...state, loading: false, screenings: [action.value.screening, ...state.screenings] };
    case DELETE_SCREENING_SUCCESS:
      return { ...state, loading: false, screenings: state.screenings.filter(s => s.id !== action.value.response.id) };
    default:
      return state;
  }
}

export function fetchScreenings(placeId: string, clientId: string): Action {
  return { type: FETCH_SCREENINGS, value: { placeId, clientId } };
}

export function fetchScreeningsSuccess(screenings: MNAScreening[]): Action {
  return { type: FETCH_SCREENINGS_SUCCESS, value: { screenings } };
}

export function fetchScreeningsFail(): Action {
  return { type: FETCH_SCREENINGS_FAIL };
}

export function saveScreening(placeId: string, clientId: string, screening: Omit<MNAScreening, 'id'>): Action {
  return { type: SAVE_SCREENING, value: { placeId, clientId, screening } };
}

export function saveScreeningSuccess(screening: MNAScreening): Action {
  return { type: SAVE_SCREENING_SUCCESS, value: { screening } };
}

export function saveScreeningFail(error: unknown): Action {
  return { type: SAVE_SCREENING_FAIL, value: { error } };
}

export function deleteScreening(placeId: string, clientId: string, screeningId: string): Action {
  return { type: DELETE_SCREENING, value: { placeId, clientId, screeningId } };
}

export function deleteScreeningSuccess(response: { id: string; status: string }): Action {
  return { type: DELETE_SCREENING_SUCCESS, value: { response } };
}

export function deleteScreeningFail(error: unknown): Action {
  return { type: DELETE_SCREENING_FAIL, value: { error } };
}

export function saveMessage(
  placeId: string,
  clientId: string,
  screeningId: string,
  message: string,
  sendNotification: boolean
): Action {
  return { type: SAVE_MESSAGE, value: { placeId, clientId, screeningId, message, sendNotification } };
}

export function saveMessageFail(error: unknown): Action {
  return { type: SAVE_MESSAGE_FAIL, value: { error } };
}

export function saveProductsSuggestion(
  placeId: string,
  clientId: string,
  screeningId: string,
  productsSuggestion: ProductSuggestion[]
): Action {
  return { type: SAVE_PRODUCTS_SUGGESTION, value: { placeId, clientId, screeningId, productsSuggestion } };
}

export function saveProductsSuggestionFail(error: unknown): Action {
  return { type: SAVE_PRODUCTS_SUGGESTION_FAIL, value: { error } };
}
