import { from, mergeMap, Observable, of } from 'rxjs';
import { Action } from '../Action';
import { ofType } from 'redux-observable';
import { ADD_ACCOUNT_ROLE, addAccountRoleSuccess, fetchAccountRoles } from '../reducers/accountRoles';
import { saveTenantAccountRole } from '../../api/api';

export function addAccountRoleEpic(action$: Observable<Action>): Observable<Action> {
  return action$.pipe(
    ofType(ADD_ACCOUNT_ROLE),
    mergeMap(action =>
      from(saveTenantAccountRole(action.value.placeId, action.value.accountRole)).pipe(
        mergeMap(() => of(addAccountRoleSuccess(), fetchAccountRoles(action.value.placeId)))
      )
    )
  );
}
