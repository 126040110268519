import { Action } from '../Action';
import { Client } from '../../api/api';

export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';

export const SAVE_CLIENT = 'SAVE_CLIENT';
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
export const SAVE_CLIENT_FAIL = 'SAVE_CLIENT_FAIL';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';

export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';

const initialState = {
  loading: false,
  clients: [] as Client[],
  error: undefined as unknown
};

type State = typeof initialState;

export function clients(state: State = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_CLIENTS:
    case SAVE_CLIENT:
    case UPDATE_CLIENT:
    case DELETE_CLIENT:
      return { ...state, loading: true, error: undefined };
    case FETCH_CLIENTS_FAIL:
    case SAVE_CLIENT_FAIL:
    case UPDATE_CLIENT_FAIL:
    case DELETE_CLIENT_FAIL:
      return { ...state, loading: false, error: action.value.error };
    case FETCH_CLIENTS_SUCCESS:
      action.value.clients.forEach((c: Client) => {
        const [firstName, lastName] = c.fullName.split(' ');
        c.firstName = firstName;
        c.lastName = lastName;
      });
      return { ...state, loading: false, clients: action.value.clients };
    case SAVE_CLIENT_SUCCESS:
      const client: Client = action.value.client;
      const [firstName, lastName] = client.fullName.split(' ');
      client.firstName = firstName;
      client.lastName = lastName;
      return { ...state, loading: false, clients: [...state.clients, client] };
    case UPDATE_CLIENT_SUCCESS:
      return { ...state, loading: false, clients: updateList(state.clients, action.value.client) };
    case DELETE_CLIENT_SUCCESS:
      return { ...state, loading: false, clients: state.clients.filter(c => c.id !== action.value.clientId) };
    default:
      return state;
  }
}

function updateList(clients: Client[], update: Client): Client[] {
  const list = [...clients];
  const index = list.findIndex(c => c.id === update.id);
  if (index !== -1) {
    list[index] = update;
  }
  return list;
}

export function fetchClients(placeId: string): Action {
  return { type: FETCH_CLIENTS, value: { placeId } };
}

export function fetchClientsSuccess(clients: Client[]): Action {
  return { type: FETCH_CLIENTS_SUCCESS, value: { clients } };
}

export function fetchClientsFail(error: unknown): Action {
  return { type: FETCH_CLIENTS_FAIL, value: { error } };
}

export function saveClient(placeId: string, client: Client): Action {
  return { type: SAVE_CLIENT, value: { placeId, client } };
}

export function saveClientSuccess(client: Client): Action {
  return { type: SAVE_CLIENT_SUCCESS, value: { client } };
}

export function saveClientFail(error: unknown): Action {
  return { type: SAVE_CLIENT_FAIL, value: { error } };
}

export function updateClient(placeId: string, client: Client): Action {
  return { type: UPDATE_CLIENT, value: { placeId, client } };
}

export function updateClientSuccess(client: Client): Action {
  return { type: UPDATE_CLIENT_SUCCESS, value: { client } };
}

export function updateClientFail(error: unknown): Action {
  return { type: UPDATE_CLIENT_FAIL, value: { error } };
}

export function deleteClient(placeId: string, clientId: string): Action {
  return { type: DELETE_CLIENT, value: { placeId, clientId } };
}

export function deleteClientSuccess(clientId: string): Action {
  return { type: DELETE_CLIENT_SUCCESS, value: { clientId } };
}

export function deleteClientFail(error: unknown): Action {
  return { type: DELETE_CLIENT_FAIL, value: { error } };
}
